import React from 'react';
import Seo from '../../components/Seo';
import NavBackBtn from '../../components/UI/navBackBtn';
import Layout from '../../components/layouts/index';
import WotschVideo from '../../assets/videos/wotsch.mp4';

const Wotsch = () => {
	return (
		<Layout>
			<Seo title='Wotsch' />
			<article className='single-project wotsch-project'>
				<div className='single-project-text'>
					<div className='heading-box'>
						<NavBackBtn url={'projects/'} label={'BACK TO PROJECTS'} />
						<h2 className='main-heading'>Wotsch</h2>
						<a
							className='link-small'
							href='https://wotsch.es'
							target='example'
							rel='noopener'
						>
							wotsch.es
						</a>
					</div>
					<div className='info-box'>
						<div className='what-how-box'>
							<div className='what-box'>
								<h3 className='small-heading'>WHAT</h3>
								<span className='label-small'>Design</span>
								<span className='label-small'>Programming</span>
							</div>
							<div className='how-box'>
								<h3 className='small-heading'>HOW</h3>
								<span className='label-small'>Angular 11</span>
								<span className='label-small'>Figma</span>
								<span className='label-small'>Firebase</span>
								<span className='label-small'>Google map</span>
								<span className='label-small'>Rxjs</span>
								<span className='label-small'>Typescript</span>
							</div>
						</div>

						<div className='text-box'>
							<p className='scndry-text'>
								The idea for Wotsch was born when I was walking around my
								neighbourhood and noticed people putting outside their homes, or
								in public places, items they wanted to give away. So I decided
								to create a community website where people can upload free
								items, and in return increase the visibility and give others an
								overview of free items in their surroundings.
							</p>
							<div className='under-hood-box'>
								<h3 className='small-heading'>UNDER THE HOOD</h3>
								<a
									className='link-small'
									href='https://gitlab.com/G.Ros/wotsch'
									target='example'
									rel='noopener'
								>
									gitlab.com/G.Ros/wotsch
								</a>
							</div>
						</div>
					</div>
				</div>
				<div className='single-project-video-container wotsch-background'>
					<video
						className='single-project-video'
						autoPlay
						loop
						muted
						playsinline
					>
						<source src={WotschVideo} type='video/mp4'></source>
						<h2 className='scndry-heading'>
							Your browser does not support HTML5 video.
						</h2>
					</video>
				</div>
			</article>
		</Layout>
	);
};

export default Wotsch;
